<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo Insumo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectUnidadesTipoInsumo || !selectUnidadesTipoInsumo.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Unidades -->
        <DataTable ref="dt" :value="tiposInsumos" v-model:selection="selectUnidadesTipoInsumo" dataKey="id" :paginator="true" :rows="50" :filters="filters" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]"
         currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gereneciar Tipos de Insumo</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>

          <Column field="descricao" header="Insumos" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Tipos Insumos</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarTipoInsumoModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        
        <!-- Modal Adição/Edição de Tipo de Insumo -->
        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Editar tipo de insumo" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Tipo de Insumo</label>
            <InputText id="bome" v-model.trim="tiposInsumo.descricao" required="true" autofocus :class="{ 'p-invalid': submitted && !tiposInsumo.descricao}" />
            <small class="p-invalid" v-if="submitted && !tiposInsumo.descricao">Descrição é obrigatória.</small>
          </div>
    
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarTipoInsumo" />
          </template>
        </Dialog>

        <!-- Modal deletar Unidade Tipo de Insumo -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tiposInsumo">Certeza que deseja apagar este item: <b>{{ tiposInsumo.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarTipoInsumo" />
          </template>
        </Dialog>

        <!-- Modal deletar vários selecionados -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tiposInsumo">Apagar Tipo de insumo?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>

  import { FilterMatchMode } from "primevue/api";

  export default {
    data() {
      return {
        tiposInsumos: [],
        tiposInsumo: {},
        selectUnidadesTipoInsumo: null,
        selectedProducts: null,
        productDialog: false,
        tipos_insumo: [],
        deleteProductDialog: false,
        filters: {},
        submitted: false,
        deleteProductsDialog: false,
      
      };
    },
    // productService: null,
    created() {
      this.initFilters();
    },
    mounted() {
      this.listarTiposInsumo();
    },


    methods: {
      // listar tipos de insumos do end-point
      listarTiposInsumo(){
        this.axios
          .post("/unidades/dados/tipos-insumo/listar")
          .then((response) => {
            this.tiposInsumos = response.data.dados;
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      openNew() {
        this.tiposInsumo = {};
        this.submitted = false;
        this.productDialog = true;
      },

      editProduct(tiposInsumo) {
        this.productDialog = true;
        this.tiposInsumo = { ...tiposInsumo };
      },

    

      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },




      // Cria / Editar  -> tipos de insumos
      salvarTipoInsumo() {
        this.submitted = true;

        if (this.tiposInsumo.id) {
          // Atualizar um tipos-insumos
          this.axios
            .post("/unidades/dados/tipos-insumo/editar", this.tiposInsumo)
            .then(() => {

              this.productDialog = false;
              this.tiposInsumo = {};
              this.listarTiposInsumo();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar novo tipos-insumos
          this.axios
            .post("/unidades/dados/tipos-insumo/adicionar", this.tiposInsumo)
            .then(() => {
              this.productDialog = false;
              this.tiposInsumo = {};
              this.listarTiposInsumo();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

    
      //Apaga um tipo de insumo
      apagarTipoInsumo() {
        this.axios
          .post("/unidades/dados/tipos-insumos/apagar", this.tiposInsumo)
          .then(() => {
            
            this.deleteProductDialog = false;
            this.tiposInsumo = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usuário apagado com sucesso!",
              life: 3000,
            });
            this.listarTiposInsumo();
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      //Apaga o Modal que abre ao selecionar que deseja apagar uma fatura
      apagarTipoInsumoModal(tiposInsumo) {
        this.tiposInsumo = tiposInsumo;
        this.deleteProductDialog = true;
      },

      // botao de excluir selecionados
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },

      
      // Faz o delete de vários items selecionados no datatable
      deleteSelectedProducts() {
        this.tiposInsumos = this.tiposInsumos.filter(
          (val) => !this.selectUnidadesTipoInsumo.includes(val)
        );
 
        let obj = this.selectUnidadesTipoInsumo;
        Object.keys(obj).forEach((key) => {
          this.axios
            .post("/unidades/dados/tipos-insumos/apagar", obj[key])
            .then(() => {
              this.listarUsuario();
              this.deleteProductDialog = false;
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Tipo Insumo apagado com sucesso!",
                life: 3000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        });
 
        this.deleteProductsDialog = false;
        this.selectUnidadesTipoInsumo = null;
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Products Deleted",
          life: 3000,
        });
      },
      /*

      */
     
     exportCSV() {
       this.$refs.dt.exportCSV();
      },
      
      
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      
    },
  };


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
